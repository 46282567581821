/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactNode } from 'react';

import { BuilderComponent, type BuilderContent } from '@builder.io/react';

import { Link } from '~src/components/Link';

type PageLayoutProps = { children: ReactNode; footer?: BuilderContent; header?: BuilderContent };

export const PageLayout = ({ children, footer, header }: PageLayoutProps) => (
  <>
    {/* @ts-ignore */}
    <BuilderComponent model='header' content={header} renderLink={Link} />

    {children}

    {/* @ts-ignore */}
    <BuilderComponent model='footer' content={footer} renderLink={Link} />
  </>
);
