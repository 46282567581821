import Head from 'next/head';

import { BuilderContentData } from '~src/model';

type MetaTagsProps = { data?: BuilderContentData };

export const MetaTags = ({ data }: MetaTagsProps) => {
  const { title, description, noIndex, noFollow } = data ?? {};

  return (
    <Head>
      <title id='title'>{title}</title>

      <meta id='og:title' property='og:title' content={title} />

      <meta id='og:description' property='og:description' content={description} />

      <meta id='description' name='description' content={description} />

      <meta id='robots-index' name='robots' content={noIndex ? 'noIndex' : 'index'} />

      <meta id='robots-follow' name='robots' content={noFollow ? 'noFollow' : 'follow'} />
    </Head>
  );
};
