import { BuilderComponent, BuilderContent, BuilderPage } from '@builder.io/react';

import { PageLayout } from '~src/layouts/PageLayout';

import { Link } from './Link';
import { MetaTags } from './MetaTags';

type BodilBuilderPageProps = { content: BuilderPage; footer: BuilderContent; header: BuilderContent };

export const BodilBuilderPage = ({ content, footer, header }: BodilBuilderPageProps) => (
  <PageLayout footer={footer} header={header}>
    <MetaTags data={content?.data} />
    <BuilderComponent model='page' content={content} renderLink={Link} />
  </PageLayout>
);
